<template>
    <footer class="the-footer flex-wrap justify-between" :class="classes">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a href="https://www.legully.com/" target="_blank" rel="nofollow">Legully</a></span>
        <span class="flex items-center">
            <span>v{{ $store.state.version }} | {{ getServerVersion  }} A Product of <a href="https://www.theletstream.com">Letstream &lt;&gt; Firmsap</a> </span>
            <span style="display:none"><a href="https://www.theletstream.com/">Letstream Ventures Pvt Ltd</a></span>
        </span>
    </footer>
</template>

<script>

export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    },
    computed:{
        getServerVersion(){
            if(!this.$store.state.server_version) return '';

            return 'API v' + this.$store.state.server_version + ' | '
        }
    }
}
</script>

<style lang="scss">
    @media screen and (max-width: 768px){
        .the-footer {
            justify-content: center !important;
        }
    }
</style>
